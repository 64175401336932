import type { FormEvent, SyntheticEvent } from 'react'
import React, { useState } from 'react'
import { encrypt } from '../api/encrypt'


const style = {
    marginTop: 20,
    'text-align': 'center'
}


const Encrypt = () => {
    const [content, setContent] = useState("")
    const [code, setCode] = useState("")

    const handleChange = (event: FormEvent<HTMLInputElement>) => {
        setContent(event.currentTarget.value);
    }

    const handleSubmit = async (event: SyntheticEvent) => {
        event.preventDefault();

        const code = await encrypt(content)

        setCode(code)
    }

    return (
        <div className="Encrypt">
            <div style={style} className="EncryptForm">
                <form onSubmit={handleSubmit}>
                    <label>Content</label>
                    <input
                        name="content"
                        type="text"
                        value={content}
                        onChange={handleChange}
                    />
                    <button type="submit">
                        Submit
                    </button>
                </form>
            </div>
            {code && <div style={style} className="Code">
                <div>Send this code to your friends !</div>
                <div>{code}</div>
            </div>}
        </div>
    )
}

export default Encrypt