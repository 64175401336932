import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import './App.css'

import Layout from './views/layout'


const App = () => (
    <div className="App">
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<Layout />} />
            </Routes>
        </BrowserRouter>
    </div>
)

export default App