import React from 'react'
import { Route, Routes } from 'react-router-dom'

import Encrypt from './encrypt'
import Decrypt from './decrypt'


const Layout = () => {
    return (
        <main>
            <Routes>
                <Route path="/encrypt" element={<Encrypt />} />
                <Route path="/decrypt" element={<Decrypt />} />
            </Routes>
        </main>
    )
}

export default Layout