import type { FormEvent, SyntheticEvent } from 'react'
import React, { useState } from 'react'
import { decrypt } from '../api/decrypt'


const style = {
    marginTop: 20,
    'text-align': 'center'
}


const Decrypt = () => {
    const [code, setCode] = useState("")
    const [secret, setSecret] = useState("")
    const [err, setErr] = useState("")

    const handleChange = (event: FormEvent<HTMLInputElement>) => {
        setCode(event.currentTarget.value);
    }

    const handleSubmit = async (event: SyntheticEvent) => {
        event.preventDefault();


        try {
            const secret = await decrypt(code)
            setSecret(secret)
        } catch (err: any) {
            setErr(err.message)
        }
    }

    return (
        <div style={style} className="Decrypt">
            <form onSubmit={handleSubmit}>
                <label>Secret code</label>
                <input
                    name="code"
                    type="text"
                    value={code}
                    onChange={handleChange}
                />
                <button type="submit">
                    Submit
                </button>
            </form>
            {secret && <div style={style} className="Secret">
                <div>Your secret is :</div>
                <div>{secret}</div>
            </div>}
            {err && <div style={style} className="Err">{err}</div>}
        </div>
    )
}

export default Decrypt